@import "main/variables_glowna";
@import "main/importer";

@import "main/bootstrap";

.default_font {
    @import "main/default_font";
}

.default_form {
    @import "main/default_form";
}

@import "../js/plugins/select2/select2";
@import "../js/plugins/jquery_mCustomScrollbar/jquery.mCustomScrollbar.scss";

@import "main/style";

// tutaj dajesz tylko style szczególne dla theme main
input[type="text"], input[type="password"], textarea {
    border-radius: $border-radius-6;
}

.default_form .select2-container .selection .select2-selection {
    border: 1px solid $color-gray-300;
    border-radius: $border-radius-4;
}

div.corner-left-bottom rect, div.corner-top-right rect {
    fill: $theme-main;
}

div.heading rect, div.category rect {
    fill: $theme-main;
}

.addon_element [fill="#4e7d1e"] {
    fill: $theme-main;
}

.block_heading-wrapper {
    .btn:after {
        border-radius: 5px
    }
}

header {
    .top_header-wrapper {
        .top_header {
            .socials-wrapper {
                .social-list {
                    ul {
                        li {
                            a {
                                border-radius: $border-radius-4;
                            }
                        }
                    }
                }
            }
        }
    }
}

.main_banner-wrapper {

    .carousel_elements_parent-wrapper {


        .nav-wrapper {
            .btns {
                button {
                    border-radius: $border-radius-4;
                    background-color: $color-white;
                }
            }
        }
    }
}

.main_banner-wrapper {
    .carousel_elements_parent-wrapper {
        .main_banner {
            border-radius: $border-radius-6;
        }
    }
}

.siblings_events_block-wrapper {
    .events_list-wrapper {
        .item-list {
            .item {


                .content {
                    .date {
                        border-radius: $border-radius-6;
                        background-color: $color_white;
                        border: 1px solid $color-gray-200;
                    }
                }
            }
        }
    }
}
.main_banner-wrapper .carousel_elements_parent-wrapper .main_banner .content_block-wrapper{
    background-color: $theme-dark;
}

.siblings_events_block-wrapper {
    .events_list-wrapper {
        .item-list {
            .item {
                display: flex;
                height: 100%;
                border-radius: $border-radius-6;
                overflow: hidden;

                .content {
                    background-color: $color-gray-100;

                    .heading-wrapper {
                        overflow: hidden;
                    }
                }
            }
        }
    }
}

.newsletter_block-wrapper {
    .newsletter_block {
        border-radius: $border-radius-6;
    }
}

.dark_block {
    background-color: $theme-dark;
}

footer {
    background-color: $theme-dark;
}

.departments_block-wrapper {
    .departments_block {
        .block_content {
            .departments_list {
                border-radius: $border-radius-6;
                margin-bottom: 25px;
            }
        }
    }
}

.send_message_block-wrapper {
    .send_message_block {
        border-radius: $border-radius-6;
    }
}

.simple_page-wrapper {
    .simple_page_block-wrapper {
        .simple_page_block {
            .block_content-wrapper {
                .faq {
                    .list {
                        .faq_item {
                            border: 2px solid $color-gray-200;
                            border-radius: $border-radius-6;
                            box-shadow: 0px 0px 16px transparent;
                        }
                    }
                }

                //Faq categories
                .faq_categories {

                    .faq_categories_list {
                        display: grid;
                        gap: 48px;

                        .faq_category_block {
                            display: grid;
                            gap: 12px;
                        }
                    }
                }


                //Map
                .map_block-wrapper {
                    //iframe{
                    //    border-radius: $border-radius-6;
                    //}
                }


                // Downloads
                .downloads {

                    .list {
                        display: grid;
                        gap: 8px;
                        width: fit-content;
                    }
                }
            }
        }
    }
}

.article_page-wrapper {
    .article_block-wrapper {
        .article_block {
            .block_content-wrapper {
                .faq {
                    .list {
                        .faq_item {
                            border: 2px solid $color-gray-200;
                            border-radius: $border-radius-6;
                            box-shadow: 0px 0px 16px transparent;
                        }
                    }
                }
            }
        }
    }
}

.downloads_item {
    background-color: $color-white;
    border: 2px solid $color-gray-200;
    border-radius: $border-radius-6;

    &:hover {
        background-color: $color-gray-100;

        .additions {
            .file_type,
            .file_size {
                background-color: $color-white !important;
                text-decoration: unset !important;
            }
        }
    }
}

.institutions_block-wrapper {
    .item-list {
        .item {
            border-radius: $border-radius-6;
            border: 1px solid $color-gray-200;
        }
    }
}

.mulitmedia_gallery_list-wrapper {
    .gallery_list {
        overflow: hidden;
        border-radius: $border-radius-6;
    }
}

.block_with_sidebar-wrapper {
    .sidebar-wrapper {
        .sidebar_list {
            .shortcuts-wrapper {
                .item-list {
                    .item {
                        background-color: $color-gray-100;
                        border-radius: $border-radius-6;
                    }
                }
            }
        }
    }
}

.block_with_sidebar-wrapper {
    .sidebar-wrapper {
        .sidebar_list {
            .calendar-wrapper {
                background-color: $color-gray-100;
                border-radius: $border-radius-6;
            }
        }
    }
}

.block_with_sidebar-wrapper {
    .sidebar-wrapper {
        .sidebar_list {
            .calendar-wrapper {
                .mycalendar2_datepicker {
                    .base_bg_color {
                        background-color: $color-gray-100;
                        border-radius: $border-radius-6;
                    }
                }
            }
        }
    }
}

.categories_tabs-wrapper {
    .categories_tabs {
        margin-bottom: 16px;

        .categories_tabs_list {
            .categories_tab {
                background-color: $color-gray-100;
                border-radius: $border-radius-66;
                color: $color-gray-600;

                &:hover,
                &.active {
                    background-color: $theme-cta;
                    color: $color-white;
                }
            }
        }
    }
}

.institutions_block-wrapper {
    .item-list {
        .item {
            .preview {
                border-radius: $border-radius-6;
            }
        }
    }
}

.newest_info_block-wrapper {
    .item-list {
        .item {
            .preview {
                border-radius: $border-radius-6 !important;
            }
        }
    }
}

.search_results_block-wrapper {
    .search_results_block_list-wrapper {
        .item-list {
            .search_results_block_item_list-wrapper {
                .item-list {
                    .item {
                        background-color: $color-gray-50;
                        border-radius: $border-radius-6;
                    }
                }
            }
        }
    }
}

#map {
    height: 450px;
}


// Footer
footer {
    color: $color-white !important;
    .menu_list, ul {

        a {
            color: $color-white !important;
        }
    }
}

.calendar_hidden_popup {
    position: absolute;
    right: 100%;
    top: 0;
    width: 400px;
    z-index: 2;
    background-color:$color-gray-100;
    border: 2px solid $color-gray-300;
    border-radius: 4%;
    box-shadow: 0px 0px 16px transparent;
}


.calendar_popup_element {
    padding-bottom: 20px;
    .header {
        display: flex;
        justify-content: space-between;
        font-weight: bold;
        padding: 20px;

        a {
            text-decoration: none;
            cursor: pointer;
            color: black;
        }
    }

    ul > li {
        display: flex;
        border-bottom: 1px solid $color-gray-300;
        padding-bottom: 10px;
        &:last-child{
            padding-bottom: 0;
            border: 0;
        }
        a, div:first-child {
            justify-content: left;
            /* align-items: center; */
            display: flex;
            width: 100%;
            margin: 0;
            padding: 5px 20px 0px 10px !important;
            flex-flow: column;

            .container_img {
                width: 25%;
            }

            .container_content {
                width: 75%;
                word-wrap: break-word;

                p {
                    font-weight: bold;
                }

                .time_span {
                    margin-top: 5px;
                    display: flex;
                }

                .time_span::before {
                    content: url("/svg/time.svg");
                    width: 23px;
                    height: 23px;
                    margin-top: 1px;
                }
            }
        }

        &:hover, &:first-child:hover {
            background-color: rgba(119, 119, 119, 0.1);
        }
    }

    a {
        margin: 10px 20px 20px;
        width: 86%;
    }
}


.popup_html.special_bg_color.base_font_color_hover {
    ul {
        display: none;
    }
}

.save_form_btn_holder{
    input[type="submit"] {
        background-color: $theme-cta !important;
        //color: $color1 !important;
        //color: $theme-cta-font !important;
        font: normal normal bold 14px/19px Lato;
        line-height: 48px !important;
        padding: 0px 24px;
        border-radius: $border-radius-4;
        border: none;
        &:hover{
            filter: brightness(120%) saturate(120%);
        }
    }
}

.btn1{
    border-radius: $border-radius-4 !important;
    //&:hover{
    //    background-color: $theme-cta-600 !important;
    //}
}


// Po poprawkach

header {
    .bottom_header-wrapper {
        .bottom_header {
            .header_menu {
                > ul {
                    > li {
                        > {
                            .link_content-wrapper {
                                &.on{
                                    a{
                                        text-decoration: underline;
                                    }
                                }
                               a{
                                   &:hover{
                                       text-decoration: underline;
                                   }
                               }
                            }
                        }
                    }
                }
            }
        }
    }
}
//
//.schools_block-wrapper{
//    .schools_block {
//        .item-list {
//            .item{
//                transition: $transition;
//                &:hover{
//                    color: $theme-cta;
//                }
//            }
//        }
//    }
//}
//
//.block_with_sidebar-wrapper {
//    .sidebar-wrapper {
//        .sidebar_list {
//            .shortcuts-wrapper {
//                .item-list {
//                    .item{
//                        .heading{
//                            transition: $transition;
//                        }
//                        &:hover{
//                            .heading{
//                                color: $theme-cta-font;
//                            }
//                        }
//                    }
//                }
//            }
//        }
//    }
//}
//
//.newest_info_block-wrapper {
//    .item-list {
//        .item {
//            .heading{
//                transition: $transition;
//            }
//            &:hover{
//                .heading{
//                    color: $theme-cta-font;
//                }
//            }
//        }
//    }
//}
//
//.siblings_events_block-wrapper {
//    .events_list-wrapper {
//        .item-list {
//            .item{
//                .heading{
//                    transition: $transition;
//                }
//                &:hover{
//                    .heading{
//                        color: $theme-cta-font;
//                    }
//                }
//            }
//        }
//    }
//}
//
//.institutions_block-wrapper {
//    .item-list {
//        .item {
//            .heading{
//                transition: $transition;
//            }
//            &:hover{
//                .heading{
//                    color: $theme-cta-font;
//                }
//            }
//        }
//    }
//}
//
//.multimedia_block-wrapper {
//    .multimedia_block {
//        .item-list {
//            .item {
//                .heading{
//                    transition: $transition;
//                }
//                &:hover{
//                    .heading{
//                        color: $theme-cta-font;
//                    }
//                }
//            }
//        }
//    }
//}

.myajaxlist_paginate_box{
    .arr_btn{
        @media only screen and (max-width: 767px) {
            width: 100%;
            text-align: center;
        }
        &.left_button{
            margin-right: 16px !important;
            @media only screen and (max-width: 767px) {
                margin-right: 0 !important;
            }
        }
    }
    >div.pages_box{
        margin-left: 0;
        width: auto !important;
        @media only screen and (max-width: 767px) {
            width: 100% !important;
        }
    }
}

.institutions_block-wrapper{
    .item-list.vertical_list {
        .item {

        }
    }
}

.glowna {
// Efekt przybliżenia zdjęcia
.events_list-wrapper, .shortcuts-wrapper, .institutions_list-wrapper, .gallery_list , .gallery_item, .multimedia_block-wrapper {
    .item {
        transition: 0.3s transform ease;

        &:hover {
            .preview {
                transform: scale(0.95);
            }
        }
    }

    .preview {
        transition: 0.3s transform ease; /* Dodaj przejście dla .preview na poziomie wyższego selektora */
    }
}

.sidebar {
    .item-list {
        a {
            //display: inline-block;
            position: relative;
            overflow: hidden; /* Ukryj część obramowania, która może wystawać */
            transition: 0.7s transform, 0.7s border, 0.7s border-radius;

            &:hover {
                &::before {
                    content: '';
                    position: absolute;
                    top: 50%;
                    left: 50%;
                    transform: translate(-50%, -50%);
                    width: 100%;
                    height: 100%;
                    border: dashed 2px var(--theme-main);
                    border-radius: 5px;
                    z-index: 3;
                }
            }
        }
    }
}

// Efekt przesunięcia
.newest_info_block-wrapper {
    .item-list {
        a.item {
            display: inline-block;
            transition: 0.3s all ease;

            &:hover {
                margin-left: 10px;
                margin-right: 10px;
            }
        }
    }
}

.shortcuts_block{
    svg{
        rect{
            fill: $theme-main;
        }
    }
}
}

.btn{
    &:hover{
        filter: none !important;
    }
    &.green_btn{
        filter: none !important;
       &:after{
           border-radius: 5px;
       }
        &:hover{
            text-decoration: underline;
            filter: none !important;
            &:after{
                filter: none !important;
            }
        }
    }
}

.mycalendar2_datepicker {
    .calendar_content {
        .days_table {
            .td.active:after{
                display: none;
            }
        }
    }
}

.td{
    &.active_selected{
        background-color: #1F6AD1;
    }
}

.menu_dots{
    &:hover,&.dots_active{
        text-decoration: underline;
    }
}

.newsletter_block-wrapper {
    .newsletter_block {
        form {
            .form_group {
                input[type="submit"] {
                    &:hover{
                        text-decoration: underline !important;
                    }
                }
            }
        }
    }
}

.btn.blue_btn{
    background-color: $theme-cta !important;
}

.login_box{
    border-radius: 6px;
}
#content.glowna{
    .gallery_list{
        .item{
            .posted{
                display: none;
            }
        }
    }
}


.subpage_top{
    margin-top: -32px;
}

.mycalendar2_datepicker .calendar_content .days_table .td.active, .mycalendar2_datepicker .calendar_content .days_table .td.active_selected{
    border: 1px solid $color-gray-100;
}


.newest_info_block-wrapper {
    .item-list {
        ul {
            margin: 0;
            > li{
                padding: 0 0 16px 0;
                width: 100% !important;
                margin-bottom: 16px;
                border-bottom: 1px solid $color-gray-200;
                &:last-child{
                    margin-bottom: 0;
                    padding-bottom: 0;
                    border-bottom: 0;
                }
                .item{
                    display: flex !important;
                    gap: 0;
                    .additions{
                        display: block !important;
                        > div{
                            display: inline-block;
                            vertical-align: top;
                            margin-right: 16px;
                            &:last-child{
                                margin-right: 0;
                            }
                        }
                    }
                    .preview{
                        width: 192px;
                        height: 108px;
                    }
                    .content{
                        padding-right: 0;
                        padding-top: 0;
                        padding-bottom: 0;
                        height: auto !important;
                        width: 100%;
                        border: 0;
                    }
                }
            }
        }
    }
}

.categories_tab{

    .categories_tabs_list & {
        color: #4A5469 !important;
        text-decoration: none;
        &.active, &:hover{
            color: $theme-cta-font !important;
        }
        &.hover{
            text-decoration: underline !important;
        }
    }
}


